import { Box, SvgIcon, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import TextInput from "shared/ui/TextInput";
import { CreateCampaign } from "store/slicers/campaigns";
import { ERequestStatus } from "store/enums/index.enum";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import ButtonLoader from "shared/ui/ButtonLoader";
import { requiredRules } from "shared/helpers/validators";
import BasicSelect from "shared/ui/Select";
import { ECreateSurveyTypes, surveyTypes } from "../constants";
import WarningIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";

interface IFormData {
  name: string;
  type: ECreateSurveyTypes;
}

const defaultValues = {
  name: "",
  type: ECreateSurveyTypes.Default,
};

const AddCampaign = ({ onSuccess }: { onSuccess: (id: number) => void }) => {
  const methods = useForm<IFormData>({
    defaultValues,
  });
  const dispatch = useAsyncDispatch();

  const { watch } = methods;

  const onSubmit = async (formData) => {
    const { meta, payload } = await dispatch(
      CreateCampaign({
        surveyMetric: "1",
        touchpoint: "1",
        type: "1",
        name: formData.name,
      })
    );

    // if (formData.type === ECreateSurveyTypes.Sms) {
    //   navigate(`/admin/sms-survey/${payload}`);
    //   return;
    // }
    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    onSuccess?.(payload);
  };

  return (
    <FormProvider {...methods}>
      <Box display="flex" justifyContent="space-between">
        <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <TextInput name={"name"} label={"Name"} rules={requiredRules} />
          <BasicSelect
            label={"Type"}
            options={surveyTypes}
            name={"type"}
            labelProp="label"
            valueProp="id"
          />
          {watch("type") === ECreateSurveyTypes.Sms && (
            <Box display="flex">
              <SvgIcon color="warning">
                <WarningIcon />
              </SvgIcon>
              <Typography sx={{ color: "warning.main", ml: 1 }}>
                Not all SatisfAi features are compatible with 2-way SMS type.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={2} display="flex" justifyContent={"flex-end"}>
        <Box>
          <ButtonLoader
            variant="outlined"
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={false}
            type="submit"
          >
            <Typography>Submit</Typography>
          </ButtonLoader>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default AddCampaign;
